<template>
  <div class="website-tab-overview">
    <v-card class="mb-7">
      <v-card-title> Website List </v-card-title>
      <v-data-table :headers="tableColumns" :item-class="websiteItemClass" :items="websitesListTable" :loading="loading">
        <template #[`item.status`]="{ item }">
          <v-chip
            small
            :color="resolveWebsiteStatusVariant(item.status)"
            :class="`${resolveWebsiteStatusVariant(item.status)}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            {{ item.status }}
          </v-chip>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mdiDotsVertical } from '@mdi/js';
import useWebsitesList from '@/views/shared/websites/useWebsitesList';

export default {
  setup() {
    const { websitesListTable, tableColumns, loading, resolveWebsiteStatusVariant, fetchWebsites } = useWebsitesList();

    const websiteItemClass = () => {
      return 'website-item';
    };

    return {
      tableColumns,
      websitesListTable,
      resolveWebsiteStatusVariant,
      fetchWebsites,
      websiteItemClass,
      loading,
      icons: {
        mdiDotsVertical,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.project-progress {
  min-width: 4rem;
}
</style>
