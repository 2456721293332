import store from '@/store';
import { ref } from '@vue/composition-api';
import { getProfile } from '@/services/jwt.service';
import Vue from 'vue';

export default function useWebsitesList() {
  const websitesListTable = ref([]);

  const tableColumns = [
    { text: 'Website Name', value: 'name', sortable: false },
    { text: 'MCC Code', value: 'mcc', sortable: false },
    { text: 'Status', value: 'status', sortable: false },
  ];

  const loading = ref(false);

  // fetch data
  const fetchWebsites = () => {
    store
      .dispatch('website/fetchWebsitesByCompanyId', {
        companyId: getProfile().companyId,
        query: '',
      })
      .then(response => {
        websitesListTable.value = response.data;
        loading.value = false;
      })
      .catch(() => {
        Vue.notify({
          type: 'error',
          title: 'Websites',
          text: `Cannot fetch websites for company with id ${getProfile().companyId}`,
        });
      });
  };

  fetchWebsites();

  const resolveWebsiteStatusVariant = status => {
    if (status === 'active') {
      return 'success';
    }
    return 'error';
  };

  return {
    websitesListTable,
    tableColumns,
    loading,
    fetchWebsites,
    resolveWebsiteStatusVariant,
  };
}
